import React from "react";
import Layout from "../../components/layout";
import {  getImage } from "gatsby-plugin-image";
import { useStaticQuery, graphql } from "gatsby";
import downloads from "../../images/downloads.jpg";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      downloadsImage: file(relativePath: { eq: "downloads.jpg" }) {
        childImageSharp {
          gatsbyImageData(width: 500)
        }
      }
    }
  `);

  const image = getImage(data.downloadsImage);

  console.log("IMAGE IS", image)

  return (
    <Layout>
      <hr />
      <div
        style={{
          color: "black",
          paddingLeft: "4rem",
          paddingRight: "4rem",
          textAlign: "center",
          marginTop: "4rem",
        }}
      >
        <div class="py-16">
          <div class="container m-auto px-6">
            <div class="lg:flex justify-between items-center">
              <div class="lg:w-6/12 lg:p-0 p-7">
                <h1 class="text-4xl font-bold leading-tight mb-5 ">
                  {" "}
                  Sell downloads
                </h1>
                <p class="text-xl">
                  {" "}
                  You can now sell your digital files in seconds through your
                  page. Everything is super easy to setup and all you have to do
                  is upload your file plus set the price.
                </p>

                <div class="py-5">
                  <a
                    href="#"
                    class="text-white rounded-full py-2 px-5 text-lg font-semibold bg-purple-600 inline-block border border-purple-600 mr-3"
                  >
                    Try for free
                  </a>
                </div>
              </div>
              <div class="lg:w-5/12 order-2">
                <img className="shadow-lg rounded max-w-full h-auto align-middle border-none"
                  style={{
                    transform:
                      "scale(1) perspective(1040px) rotateY(191deg) rotateX(2deg) rotate(2deg)",
                  }} src={`${downloads}`} width="auto" />

              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
